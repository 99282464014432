import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import 'index.css';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown } from '@material-ui/icons';
import PreloaderTable from 'lib/components/Preloaders/Table';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    margin: '2rem 0',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Rubik, sans-serif',
  },
  h2: {
    fontSize: '24px',
    fontWeight: '500',
    margin: '0',
    textAlign: 'center',
  },
  p: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '0',
    color: '#878181',
    textAlign: 'center',
  },

  button: {
    fontFamily: 'Rubik, sans-serif',
    padding: '.75rem 2rem',
    backgroundColor: '#2F49D0',
    color: '#fff',
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  dropDown: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: 'max-content',
    transition: '.4s',
    top: 40,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: '12px 0',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    borderRadius: '5px',
  },
  root: {
    position: 'relative',
    transition: '.4s',
  },
  content: {
    padding: '5px 12px',
    transition: '.4s',
    borderRadius: '5px',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
}));

const EmptyState = ({
  title,
  subtitle,
  link,
  btnContent,
  isDropdown,
  items,
}) => {
  const classes = useStyles();
  const [isMenu, setIsMenu] = React.useState(null);

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.h2}>{title}</h2>
      <p className={classes.p}>{subtitle}</p>
      {btnContent && (
        <React.Fragment>
          {isDropdown ? (
            <Button
              color="primary"
              variant="contained"
              endIcon={<KeyboardArrowDown />}
              onClick={(e) => setIsMenu(e.currentTarget)}>
              {btnContent}
            </Button>
          ) : (
            <React.Fragment>
              {typeof link == 'string' ? (
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to={link}>
                  {btnContent}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => link()}>
                  {btnContent}
                </Button>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Menu
        anchorEl={isMenu}
        keepMounted
        open={Boolean(isMenu)}
        onClose={() => setIsMenu(null)}>
        {items?.map((item, i) => (
          <MenuItem
            onClick={() => {
              item?.open();
              setIsMenu(null);
            }}
            key={i}>
            {item?.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default EmptyState;
