import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Button,
  FormHelperText,
  Paper,
  TextField,
} from '@material-ui/core';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import authValidation from 'validations/auth/authValidation';
import { Link, useHistory } from 'react-router-dom';
import httpService from 'service/httpService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import validateImage from 'utils/imageSizeValidator';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { OrganizationContext } from 'context/organization/organization';
import useUserData from 'hooks/useUserData';
import _ from 'lodash';
import Header from '../components/header';
import { PATHNAMES } from 'lib/constants/pathnames';
import { CURRENCIES } from 'static/currencies';
import ReactSelect, { CreatableSelect } from 'lib/components/ReactSelect';
import useFetch from 'hooks/useFetch';
import currencyservice from 'service/currencyservice';
import publicService from 'service/publicService';
import clearCreatableFields from 'utils/clearCreatableFields';
import userService from 'service/userService';

const initialData = {
  countries: [],
  countriesMap: {},
  states: [],
  statesMap: {},
};

const initialValues = {
  name: '',
  phone: '',
  email: '',
  image: '',
  currency: 'NGN',
  imageURL: '',
  address: {
    state: '',
    country: '',
  },
};

const LINK = `${location?.pathname}${location.search}`;
const REDIRECT_URL = `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`;

const CreateOrganization = () => {
  const history = useHistory();
  const imageRef = React.useRef();
  const { user } = useUserData();
  const [selectData, setSelectData] = React.useState(initialData);

  const { setRefetch } = React.useContext(OrganizationContext);
  const [defaultValues, setDefautValues] = React.useState(initialValues);
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, errors, control, reset, watch, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.CREATE_ORGANIZATION),
    defaultValues: initialValues,
  });

  const searchParams = new URLSearchParams(location.search);
  const isNewAccount = searchParams.get('account') || '';
  const REFERRER_CODE = searchParams.get('referrerCode') || '';

  React.useEffect(() => {
    if (!user) return;
    reset({ ...initialValues, ..._.pick(user, ['phone', 'email']) });
  }, [user]);

  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) {
      history.replace(REDIRECT_URL);
    }
  }, [user]);

  const country = watch('address.country', '');
  const countryId = selectData.countriesMap[country];

  const currencyQuery = useFetch({
    qKey: 'currencies',
    requestFn: currencyservice.getCurrencies,
  });

  const countriesQuery = useFetch({
    qKey: 'countries',
    requestFn: () => publicService.getCountries(),
    onSuccess: (data) => {
      handleAddData('countries', data?.data, 'countriesMap');
    },
  });

  const statesQuery = useFetch({
    qKey: 'states',
    isEnabled: !!countryId,
    keys: [countryId],
    requestFn: () => publicService.getStates({ countryId }),
    onSuccess: (data) => handleAddData('states', data?.data, 'statesMap'),
  });

  let DEFAULT_CURRENCIES = currencyQuery?.data?.data?.map((item) => {
    return item?.currencyName;
  }) || [{ name: 'Nigerian Naira', code: 'NGN' }];

  DEFAULT_CURRENCIES = CURRENCIES.filter((item) => {
    return DEFAULT_CURRENCIES?.includes(item?.code);
  });

  const currenciesMap = {};
  for (const item of DEFAULT_CURRENCIES) {
    currenciesMap[item?.country] = item?.code;
  }
  return (
    <div className="container-xxl">
      <Header />
      <div style={{ minHeight: '90vh' }} className="d-flex align-items-center">
        <Paper
          className="p-3 py-5 border mx-auto my-4 rounded-4"
          style={{ maxWidth: 600, width: '100%' }}>
          <div className="mb-4 mx-auto" style={{ maxWidth: 340 }}>
            <div className="fs-4 text-center">Organization Profile</div>
            <div className="text-center fs-sm">
              We need some information about your organization
            </div>
          </div>

          <form
            className="mx-auto"
            onSubmit={handleSubmit(save)}
            style={{ maxWidth: 450 }}>
            <div className="mb-3">
              <input
                accept=".jpg, .png, .jpeg"
                className="d-none"
                ref={imageRef}
                type="file"
                onChange={handleChangeImage}
              />
              <div className="d-flex flex-column gap-3 align-items-center">
                <Avatar
                  onClick={() => imageRef.current.click()}
                  style={{ width: 80, height: 80, marginRight: 10 }}
                  src={defaultValues.imageURL}
                  alt="organization logo">
                  <AddAPhotoIcon />
                </Avatar>
                <div className="text-center">
                  <label htmlFor="logoInput">
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => imageRef.current.click()}
                      style={{ textTransform: 'none' }}
                      endIcon={<CloudUploadOutlinedIcon />}>
                      Upload Image
                    </Button>
                  </label>
                  <div className="text-muted fs-xs fst-italic">
                    Maximum size of 2mb
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label>
                Name of Business: <span className="text-danger">*</span>
              </label>
              <Controller
                name="name"
                control={control}
                render={(props) => (
                  <TextField
                    type="text"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={props.value}
                    placeholder="Enter the full name of your business"
                    onChange={(e) => props.onChange(e.target.value)}
                    helperText={errors['name']?.message}
                    error={!!errors['name']}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <label>Business Email:</label>
              <Controller
                name="email"
                control={control}
                render={(props) => (
                  <TextField
                    type="email"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={props.value}
                    placeholder="Enter business email here"
                    onChange={(e) => props.onChange(e.target.value)}
                    helperText={errors['email']?.message}
                    error={!!errors['email']}
                  />
                )}
              />
            </div>

            <div className="mb-3">
              <label>Business Phone Number:</label>
              <Controller
                name="phone"
                control={control}
                render={(props) => (
                  <PhoneInput
                    country={'ng'}
                    value={`${props.value}`}
                    placeholder="Enter business phone number"
                    inputProps={{ name: 'phone', required: true }}
                    inputStyle={{ height: '38px', width: '100%' }}
                    onChange={(v) => props.onChange(v)}
                  />
                )}
              />
              {errors.phone && (
                <div className="fs-xs text-danger ms-4 mt-1">
                  {errors.phone?.message}
                </div>
              )}
            </div>

            <div className="mb-3">
              <label>
                Country <span className="text-danger">*</span>
              </label>
              <Controller
                name="address.country"
                control={control}
                render={(props) => (
                  <ReactSelect
                    value={props.value}
                    options={selectData.countries}
                    classes="react-select-sm"
                    isLoading={countriesQuery.isLoading}
                    handleChange={(v) => {
                      setSelectData((prev) => ({ ...prev, states: [] }));
                      props.onChange(v.value);
                      setValue('state', '');
                      if (currenciesMap[v?.code]) {
                        setValue('currency', currenciesMap[v?.code]);
                      }
                      // clearCreatableFields();
                    }}
                  />
                )}
              />
              {errors?.address?.country && (
                <FormHelperText className="text-danger mt-1">
                  {errors?.address?.country?.message}
                </FormHelperText>
              )}
            </div>
            <div className="mb-3">
              <label>
                State / Region <span className="text-danger">*</span>
              </label>
              <Controller
                name="address.state"
                control={control}
                render={(props) => (
                  <CreatableSelect
                    isClearable
                    isSearchable
                    value={props.value}
                    options={selectData.states}
                    classes="react-select-sm"
                    isLoading={statesQuery.isLoading}
                    handleChange={(v) => props.onChange(v?.value)}
                    onCreateOption={(v) => {
                      const value = { label: v, value: v };
                      props.onChange(value?.value);
                      handleAddData('states', [value, ...selectData.states]);
                    }}
                  />
                )}
              />
              {errors?.address?.state && (
                <FormHelperText className="text-danger mt-1">
                  {errors?.address?.state?.message}
                </FormHelperText>
              )}
            </div>
            <div className="mb-5">
              <label>
                Business Currency <span className="text-danger">*</span>
              </label>
              <Controller
                name="currency"
                control={control}
                render={(props) => (
                  <ReactSelect
                    value={props.value}
                    classes="react-select-sm"
                    options={DEFAULT_CURRENCIES.map((item) => ({
                      label: `${item?.name} (${item?.code})`,
                      value: item?.code,
                    }))}
                    handleChange={(v) => props.onChange(v?.value)}
                  />
                )}
              />
              {errors?.currency && (
                <FormHelperText className="text-danger mt-1">
                  {errors.currency?.message}
                </FormHelperText>
              )}
            </div>

            <div className="mb-3">
              <Button
                fullWidth
                size="medium"
                variant="contained"
                color="primary"
                type="submit"
                style={{ textTransform: 'none' }}
                disabled={isLoading}
                startIcon={isLoading && <BTNLoader />}>
                {!!isNewAccount ? 'Complete Registration' : 'Proceed'}
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );

  function handleChangeImage(event) {
    const file = event.target.files[0];
    if (!file) return;
    if (validateImage(file)) {
      httpService.toast.error('Image size too large');
      return;
    }
    setDefautValues((prev) => ({
      ...prev,
      image: file,
      imageURL: URL.createObjectURL(file),
    }));
  }

  async function save(formData) {
    if (defaultValues.image) formData.image = defaultValues.image;
    try {
      setIsLoading(true);
      const { data } = await userService.createOrganization(formData);
      httpService.toast.success('Organization created successfully.');
      userService.setOrgID(data?.data?.id);
      setRefetch((prev) => !prev);
      setTimeout(() => _handleSaveComplete(data), 1000);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  function _handleSaveComplete(data) {
    const link = `/plans?organization_id=${data?.data?.id}&referrerCode=${REFERRER_CODE}`;
    location.replace(link);
  }

  function handleAddData(key, value, mapName) {
    let newValue = [];
    if (Array.isArray(value) && !!mapName) {
      newValue = value?.map((d) => ({
        label: d?.name,
        value: d?.name,
        code: d?.iso2,
      }));
      const mapValues = {};
      for (const item of value) {
        mapValues[item?.name] = item?.id;
      }
      setSelectData((prev) => ({
        ...prev,
        [key]: [...prev[key], ...newValue],
        [mapName]: mapValues,
      }));
      return;
    }

    setSelectData((prev) => ({ ...prev, [key]: value }));
  }
};

export default CreateOrganization;
