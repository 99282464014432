import React from 'react';
import { OrganizationContext } from 'context/organization/organization';
import { UserContext } from 'context/user/user';
import { useContext } from 'react';
import Cookies from 'js-cookie';
import { GlobalContext } from 'context/global/Index';

const accessToken = Cookies.get('_access_auth');

function useUserData() {
  const { taxRate } = React.useContext(GlobalContext);
  const { user, setRefetch } = React.useContext(UserContext);
  const { admin, organization, plan } = React.useContext(OrganizationContext);
  const excludedPermissions = admin?.excludedPermissions || [];

  const permissions = {
    createInvoice: !excludedPermissions?.includes('invoice.CREATE'),
    editInvoice: !excludedPermissions?.includes('invoice.EDIT'),
    manageInvoice: !excludedPermissions?.includes('invoice.MANAGE'),
    viewInvoice: !excludedPermissions?.includes('invoice.VIEW'),
    createCreditNote: !excludedPermissions?.includes(
      'invoice.CREATE_CREDIT_NOTE',
    ),
    editCreditNote: !excludedPermissions?.includes('invoice.EDIT_CREDIT_NOTE'),
    viewCreditNote: !excludedPermissions?.includes('invoice.VIEW_CREDIT_NOTE'),
    createCustomer: !excludedPermissions?.includes(
      'accounting.customer.CREATE',
    ),
    editCustomer: !excludedPermissions?.includes('accounting.customer.EDIT'),
    deleteCustomer: !excludedPermissions?.includes(
      'accounting.customer.DELETE',
    ),
    importCustomer: !excludedPermissions?.includes(
      'accounting.customer.IMPORT',
    ),
    viewCustomer: !excludedPermissions?.includes('accounting.customer.VIEW'),
  };

  return {
    plan,
    user,
    admin,
    taxRate,
    organization,
    permissions,
    isAuth: !!accessToken,
    currency: organization?.currency || 'NGN',
    resetUserData: () => setRefetch((prev) => !prev),
    authAdministrator: admin,
    excludedPermissions,
  };
}

export default useUserData;
