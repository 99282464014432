export const PATHNAMES = {
  BUDGETS: '/dashboard/budget',
  EMPLOYEES: '/dashboard/employees',
  BILLINGS: '/dashboard/billing',
  EMPLOYEES_OVERVIEW: '/dashboard/employees/view/overview',
  CONVERSION_BALANCE: '/dashboard/conversion-balance',

  ACCOUNT: '/account',
  SIGN_IN: '/account/signin',
  SIGN_UP: '/account/signup',
  SIGN_OUT: '/account/signout',
  ACCOUNT_VERIFICATION: '/account/verification',
  FORGOT_PASSWORD: '/account/recovery/forgot-password',
  FORGOT_PASSWORD_SUCCESS: '/account/recovery/forgot-password/sent',
  RESET_PASSWORD: '/account/recovery/reset-password',
  NEW_INVITES: '/invitations',

  PROFILE: '/account/profile',
  PASSWORD_AND_SECORIRY: '/account/password-and-security',
  INVITATIONS: '/account/invitations',
  ACCOUNT_DETAILS: '/account/account-details',

  ORGANIZATIONS: '/organizations',
  FEATURE_SELECTION: '/feature-selection',
  CREATE_ORGANIZATIONS: '/organizations/new',

  PLANS: '/plans',

  REFERRAL: '/referral',
  REFERRAL__NEW: '/referral/new',
  REFERRAL__BUSINESSES: '/referral/businesses',
  REFERRAL__BUSINESS_INFO: '/referral/businesses/:id',
  REFERRAL__DASHBOARD: '/referral/dashboard',
  REFERRAL__EARNINGS: '/referral/earnings',

  INVOICES: '/dashboard/invoices',
  INVOICES__CREATE: '/dashboard/invoices/new',
  INVOICES__EDIT: '/dashboard/invoices/edit',
  INVOICES__VIEW: '/dashboard/invoices/view',
  INVOICES__VIEW_BULK: '/dashboard/invoices/bulk/view',
  INVOICES__VIEW_BULK: '/dashboard/invoices/bulk/view',
  CREDIT_NOTES__CUSTOMER_SELECT:
    '/dashboard/invoices/credit-notes/select-customer',
  CREDIT_NOTES__CREATE: '/dashboard/invoices/credit-notes/new',
  CREDIT_NOTES__EDIT: '/dashboard/invoices/credit-notes/edit',
  CREDIT_NOTES__VIEW: '/dashboard/invoices/credit-notes/',
  CREDIT_NOTES: '/dashboard/invoices/credit-notes',
};
