import http from './httpService';
import userService, { User } from './userService';

const user = new User();
const orgID = user.getOrgID();
const route = `/user/cp/org/${orgID}`;

export class Subscription {
  constructor() {
    this.organizationId = userService.getOrgID();
    this.route = `/user/cp/org/${orgID}`;
    this.endpoints = {
      freeTrial: `${this.route}/billing/free-trial-subscription-charge`,
      replaceCard: `/user/cp/org/${this.organizationId}/billing/generate-managed-subscription-update-link`,
      removeCard: `/user/cp/org/${this.organizationId}/billing/subscription-payment-card-removal`,
    };
  }
  getCurrentSub() {
    return http.get(`${route}/billing/subscription`);
  }
  cancelSub() {
    return http.put(`${route}/billing/subscription-cancelation`, { orgID });
  }

  async replaceCard() {
    return await http.post(this.endpoints.replaceCard, {});
  }
  async removeCard() {
    return await http.post(this.endpoints.removeCard, {});
  }
  getPlans() {
    return http.get(`${route}/billing/plans`);
  }
  invoices() {
    return http.get(`${route}/billing/invoices`);
  }
  getReceipts() {
    return http.get(`${route}/billing/payments`);
  }
  getSingleReceipt(id) {
    return http.get(`${route}/billing/payments/single?id=${id}`);
  }
  subscribe(planId, billingInterval) {
    const data = { planId, billingInterval };
    return http.post(`${route}/billing/subscription-payment`, data);
  }
  subscriptionPayment(data) {
    return http.post(`${route}/billing/subscription-payment`, data);
  }
  async freeTrial(planId) {
    const data = { planId };
    return await http.post(this.endpoints.freeTrial, data);
  }

  formatPlans(data = []) {
    const formatedPlans = data?.map((item) => {
      if (item?.name === 'Premium') {
        return {
          index: 3,
          ...item,
          heading: 'Suitable for Medium Scale Enterprises',
          prevPrice: {
            MONTHLY: 15000,
            QUARTERLY: 35000,
            BIANNUALLY: 65000,
            ANNUALLY: 100000,
          },
          content: [
            'All Standard features, plus',
            'Convert multiple currencies',
            'Switch between five invoice and estimates templates',
            'Manage fixed assets',
            'Create and manage budget',
            'Invite and manage unlimited employees',
            'Create and manage unlimited employees',
            'Manage employee performance evaluation',
            'Schedule staff meetings',
            'Create and manage unlimited branches and departments',
            'Create internal memos',
            'Staff performance evaluation reports',
          ],
        };
      } else if (item?.name === 'Standard') {
        return {
          index: 2,
          ...item,
          heading: 'Suitable for Small Scale Enterprises',
          prevPrice: {
            MONTHLY: 8500,
            QUARTERLY: 18500,
            BIANNUALLY: 33500,
            ANNUALLY: 50000,
          },
          content: [
            'All Simple-Start features, plus',
            'Send unlimited invoices',
            'Switch between five invoice and estimates templates',
            'Customer grouping',
            'Journals',
            'Create 5 departments and 1 branch',
            'Customer and advance financial reporting',
            'Create Quotation',
            'Employee Transfer',
            'Employee Suspension',
            'Employee Promotion',
            'Create Internal memo',
            'Support one admin user plus 5 sub-admin users',
          ],
        };
      } else if (item?.name === 'Simple Start') {
        return {
          index: 1,
          ...item,
          heading: 'Suitable for Micro and Small Scale Businesses',
          prevPrice: {
            MONTHLY: 5000,
            QUARTERLY: 9000,
            BIANNUALLY: 15000,
            ANNUALLY: 20000,
          },
          content: [
            'Dashboard Customization',
            'Track income and expenses',
            'Send 20 Invoices per month',
            'Select between 2 invoice templates',
            'Reconcile bank transactions',
            'Manage inventory',
            'Invite and manage 5 employees',
            'Manage employee leave',
            'Manage Termination and Retirement',
            'Run Payroll',
            'Create 1 department',
            'Tax calendar',
            'Upload Signature / Sign directly functionality',
            'Receive payment from customers',
            'Suport one admin user only',
          ],
        };
      }
    });
    return formatedPlans?.sort((a, b) => a?.index - b?.index);
  }
}

export default new Subscription();
