export const CURRENCIES = [
  { name: 'Nigerian Naira', code: 'NGN', country: 'NG' },
  { name: 'Afghan Afghani', code: 'AFA', country: 'AF' },
  { name: 'Albanian Lek', code: 'ALL', country: 'AL' },
  { name: 'Algerian Dinar', code: 'DZD', country: 'DZ' },
  { name: 'Angolan Kwanza', code: 'AOA', country: 'AO' },
  { name: 'Argentine Peso', code: 'ARS', country: 'AR' },
  { name: 'Armenian Dram', code: 'AMD', country: 'AM' },
  { name: 'Aruban Florin', code: 'AWG', country: 'AW' },
  { name: 'Australian Dollar', code: 'AUD', country: 'AU' },
  { name: 'Azerbaijani Manat', code: 'AZN', country: 'AZ' },
  { name: 'Bahamian Dollar', code: 'BSD', country: 'BS' },
  { name: 'Bahraini Dinar', code: 'BHD', country: 'BH' },
  { name: 'Bangladeshi Taka', code: 'BDT', country: 'BD' },
  { name: 'Barbadian Dollar', code: 'BBD', country: 'BB' },
  { name: 'Belarusian Ruble', code: 'BYR', country: 'BY' },
  { name: 'Belgian Franc', code: 'BEF', country: 'BE' },
  { name: 'Belize Dollar', code: 'BZD', country: 'BZ' },
  { name: 'Bermudan Dollar', code: 'BMD', country: 'BM' },
  { name: 'Bhutanese Ngultrum', code: 'BTN', country: 'BT' },
  { name: 'Bitcoin', code: 'BTC', country: null },
  { name: 'Bolivian Boliviano', code: 'BOB', country: 'BO' },
  { name: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM', country: 'BA' },
  { name: 'Botswanan Pula', code: 'BWP', country: 'BW' },
  { name: 'Brazilian Real', code: 'BRL', country: 'BR' },
  { name: 'British Pound Sterling', code: 'GBP', country: 'GB' },
  { name: 'Brunei Dollar', code: 'BND', country: 'BN' },
  { name: 'Bulgarian Lev', code: 'BGN', country: 'BG' },
  { name: 'Burundian Franc', code: 'BIF', country: 'BI' },
  { name: 'Cambodian Riel', code: 'KHR', country: 'KH' },
  { name: 'Canadian Dollar', code: 'CAD', country: 'CA' },
  { name: 'Cape Verdean Escudo', code: 'CVE', country: 'CV' },
  { name: 'Cayman Islands Dollar', code: 'KYD', country: 'KY' },
  { name: 'CFA Franc BCEAO', code: 'XOF', country: 'SN' },
  { name: 'CFA Franc BEAC', code: 'XAF', country: 'CM' },
  { name: 'CFP Franc', code: 'XPF', country: 'PF' },
  { name: 'Chilean Peso', code: 'CLP', country: 'CL' },
  { name: 'Chinese Yuan', code: 'CNY', country: 'CN' },
  { name: 'Colombian Peso', code: 'COP', country: 'CO' },
  { name: 'Costa Rican Colón', code: 'CRC', country: 'CR' },
  { name: 'Croatian Kuna', code: 'HRK', country: 'HR' },
  { name: 'Czech Republic Koruna', code: 'CZK', country: 'CZ' },
  { name: 'Danish Krone', code: 'DKK', country: 'DK' },
  { name: 'Dominican Peso', code: 'DOP', country: 'DO' },
  { name: 'East Caribbean Dollar', code: 'XCD', country: 'AG' },
  { name: 'Egyptian Pound', code: 'EGP', country: 'EG' },
  { name: 'Euro', code: 'EUR', country: 'EU' },
  { name: 'Fijian Dollar', code: 'FJD', country: 'FJ' },
  { name: 'Gambian Dalasi', code: 'GMD', country: 'GM' },
  { name: 'Georgian Lari', code: 'GEL', country: 'GE' },
  { name: 'Ghanaian Cedi', code: 'GHS', country: 'GH' },
  { name: 'Guatemalan Quetzal', code: 'GTQ', country: 'GT' },
  { name: 'Haitian Gourde', code: 'HTG', country: 'HT' },
  { name: 'Honduran Lempira', code: 'HNL', country: 'HN' },
  { name: 'Hong Kong Dollar', code: 'HKD', country: 'HK' },
  { name: 'Hungarian Forint', code: 'HUF', country: 'HU' },
  { name: 'Indian Rupee', code: 'INR', country: 'IN' },
  { name: 'Indonesian Rupiah', code: 'IDR', country: 'ID' },
  { name: 'Iranian Rial', code: 'IRR', country: 'IR' },
  { name: 'Iraqi Dinar', code: 'IQD', country: 'IQ' },
  { name: 'Israeli New Sheqel', code: 'ILS', country: 'IL' },
  { name: 'Japanese Yen', code: 'JPY', country: 'JP' },
  { name: 'Kenyan Shilling', code: 'KES', country: 'KE' },
  { name: 'Kuwaiti Dinar', code: 'KWD', country: 'KW' },
  { name: 'Malaysian Ringgit', code: 'MYR', country: 'MY' },
  { name: 'Mexican Peso', code: 'MXN', country: 'MX' },
  { name: 'Moroccan Dirham', code: 'MAD', country: 'MA' },
  { name: 'Myanmar Kyat', code: 'MMK', country: 'MM' },
  { name: 'Nepalese Rupee', code: 'NPR', country: 'NP' },
  { name: 'New Zealand Dollar', code: 'NZD', country: 'NZ' },
  { name: 'Norwegian Krone', code: 'NOK', country: 'NO' },
  { name: 'Omani Rial', code: 'OMR', country: 'OM' },
  { name: 'Pakistani Rupee', code: 'PKR', country: 'PK' },
  { name: 'Philippine Peso', code: 'PHP', country: 'PH' },
  { name: 'Polish Zloty', code: 'PLN', country: 'PL' },
  { name: 'Qatari Rial', code: 'QAR', country: 'QA' },
  { name: 'Russian Ruble', code: 'RUB', country: 'RU' },
  { name: 'Saudi Riyal', code: 'SAR', country: 'SA' },
  { name: 'Singapore Dollar', code: 'SGD', country: 'SG' },
  { name: 'South African Rand', code: 'ZAR', country: 'ZA' },
  { name: 'South Korean Won', code: 'KRW', country: 'KR' },
  { name: 'Swiss Franc', code: 'CHF', country: 'CH' },
  { name: 'Thai Baht', code: 'THB', country: 'TH' },
  { name: 'Turkish Lira', code: 'TRY', country: 'TR' },
  { name: 'Ukrainian Hryvnia', code: 'UAH', country: 'UA' },
  { name: 'United Arab Emirates Dirham', code: 'AED', country: 'AE' },
  { name: 'US Dollar', code: 'USD', country: 'US' },
  { name: 'Vietnamese Dong', code: 'VND', country: 'VN' },
  { name: 'Zimbabwean dollar', code: 'ZWL', country: 'ZW' },
];
