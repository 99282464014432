import React from 'react';
import httpService from 'service/httpService';

const usePost = ({ requestFn, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  async function mutate(...variables) {
    try {
      setIsLoading(true);
      const result = await requestFn(...variables);
      const passedVariable = variables?.length > 0 ? variables[0] : undefined;

      if (onSuccess) onSuccess(result?.data, passedVariable);
      setData(result?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      httpService.errorHandler(error);
      setIsLoading(false);
      if (onError) onError(error);
      setError(error);
    }
  }

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};

export default usePost;
